import Cookies from "js-cookie";

const handleCookieBanner = () => {
  const cookiesBanner = document.querySelector("#cookies-banner");
  const cookiesBannerCloseBtn = cookiesBanner.querySelector(
    ".cookies-banner__close",
  );
  const cookiesConsentKey = "cookies-consent";

  const cookiesConsent = Cookies.get(cookiesConsentKey);

  if (cookiesConsent !== "true") {
    cookiesBanner.classList.remove("is-closed");
  }

  cookiesBannerCloseBtn.addEventListener("click", () => {
    Cookies.set(cookiesConsentKey, "true", { expires: 365 });
    cookiesBanner.classList.add("is-closed");
  });
};

export default handleCookieBanner;
