export default function contactMap() {
  const voivodshipsEl = document.querySelector(".js-voivodships");
  const contactMapEl = document.querySelector(".js-contact-map");
  if (!voivodshipsEl || !contactMapEl) {
    return;
  }
  const voivodshipPaths = contactMapEl.querySelectorAll("path");

  // filter out empty voivodships
  const clickableVoivodshipPaths = [...voivodshipPaths].filter(el => {
    const voivodshipKey = el.dataset.voivodshipKey;

    const voivodshipEl = voivodshipsEl.querySelector(
      `.voivodship[data-voivodship-key="${voivodshipKey}"]`,
    );
    if (!voivodshipKey || voivodshipEl.classList.contains("is-empty")) {
      return false;
    }
    return true;
  });

  clickableVoivodshipPaths.forEach(path => {
    path.classList.add("is-clickable");
  });

  clickableVoivodshipPaths.forEach(path => {
    path.addEventListener("mouseover", function() {
      // get corresponding voivodship div
      const voivodshipKey = path.dataset.voivodshipKey;
      if (!voivodshipKey) {
        return;
      }
      const voivodshipEl = voivodshipsEl.querySelector(
        `.voivodship[data-voivodship-key="${voivodshipKey}"]`,
      );
      // voivodship already active
      if (voivodshipEl.classList.contains("is-active")) {
        return;
      }

      // clear active voivodship
      const activeVoivodship = voivodshipsEl.querySelector(
        ".voivodship.is-active",
      );
      if (activeVoivodship) {
        activeVoivodship.classList.remove("is-active");
      }
      // clear active path
      const activeVoivodshipPath = contactMapEl.querySelector(
        ".voivodship-path.is-active",
      );
      if (activeVoivodshipPath) {
        activeVoivodshipPath.classList.remove("is-active");
      }
      // set new active path and voivodship
      voivodshipEl.classList.add("is-active");
      path.classList.add("is-active");
    });
  });
}
