import snackbar from "snackbar";

import { fetchFromApi } from "./api";
import { GENERAL_CONNECTION_ERROR } from "./errors";

class FormLoadingController {
  constructor(form) {
    this.isLoading = false;
    this.submitButton = form.querySelector(".js-form-submit-btn");

    this.enableLoading = function() {
      this.submitButton.classList.add("is-loading");
      this.isLoading = true;
    };

    this.disableLoading = function() {
      this.submitButton.classList.remove("is-loading");
      this.isLoading = false;
    };
  }
}

function validateForm({ form, selectedQuestionType, recaptcha, email }) {
  const questionTypesErrorEl = form.querySelector(".js-question-types-error");
  if (questionTypesErrorEl) {
    if (!selectedQuestionType) {
      questionTypesErrorEl.style.display = "block";
      questionTypesErrorEl.scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
      return false;
    } else {
      questionTypesErrorEl.style.display = "none";
    }
  }

  const emailError = form.querySelector(".js-email-error");
  if (emailError) {
    if (!isEmailValid(email)) {
      emailError.style.display = "block";
      emailError.scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
      return false;
    } else {
      emailError.style.display = "none";
    }
  }

  const recaptchaErrorEl = form.querySelector(".js-recaptcha-error");
  if (recaptchaErrorEl) {
    if (!recaptcha) {
      recaptchaErrorEl.style.display = "block";
      recaptchaErrorEl.scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
      return false;
    } else {
      recaptchaErrorEl.style.display = "none";
    }
  }

  return true;
}

function createRecaptchaScript() {
  return new Promise(resolve => {
    window.handleRecatpchaLoad = function() {
      const recaptchaEl = document.getElementById("contact-form__recaptcha");
      const sitekey = recaptchaEl.dataset.sitekey;
      const widgetId = grecaptcha.render(recaptchaEl, {
        sitekey,
      });
      resolve(widgetId);
    };

    const script = document.createElement("script");
    script.setAttribute("async", "");
    script.setAttribute("defer", "");
    script.src =
      "https://www.google.com/recaptcha/api.js?render=explicit&onload=handleRecatpchaLoad";
    document.head.appendChild(script);
  });
}

function isEmailValid(email) {
  const regexp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regexp.test(email);
}

export default async function contactForm() {
  const form = document.querySelector(".js-contact-form");
  if (!form) {
    return;
  }

  const loadingController = new FormLoadingController(form);

  loadingController.enableLoading();
  const recaptchaWidgetId = await createRecaptchaScript();
  loadingController.disableLoading();

  let selectedQuestionType;
  const questionTypeButtons = form.querySelectorAll(".js-question-type__btn");

  questionTypeButtons.forEach(el => {
    el.addEventListener("click", function(e) {
      if (e.target.classList.contains("is-active")) {
        return;
      }
      questionTypeButtons.forEach(btn => {
        btn.classList.remove("is-active");
      });
      e.target.classList.add("is-active");
      selectedQuestionType = e.target.dataset.questionType;
    });
  });

  form.addEventListener("submit", async e => {
    e.preventDefault();
    if (loadingController.isLoading) {
      return;
    }

    const inputs = form.elements;
    const firstName = inputs["first_name"].value;
    const lastName = inputs["last_name"].value;
    const senderAddress = inputs["sender_address"].value;
    const company = inputs["company_name"].value;
    const message = inputs["message"].value;
    const recaptcha = grecaptcha.getResponse(recaptchaWidgetId);

    const isFormValid = validateForm({
      form,
      selectedQuestionType,
      recaptcha,
      email: senderAddress,
    });

    if (!isFormValid) {
      return;
    }

    try {
      loadingController.enableLoading();

      await fetchFromApi("/mail/send/", {
        method: "POST",
        data: {
          sender_address: senderAddress,
          topic: selectedQuestionType,
          first_name: firstName,
          last_name: lastName,
          message,
          recaptcha,
          company,
        },
      });

      const inputsToClear = [
        inputs["first_name"],
        inputs["last_name"],
        inputs["sender_address"],
        inputs["company_name"],
        inputs["message"],
      ];

      inputsToClear.forEach(input => {
        input.value = "";
      });

      selectedQuestionType = "";
      questionTypeButtons.forEach(btn => {
        btn.classList.remove("is-active");
      });

      snackbar.show("Wiadomość została wysłana");
    } catch (error) {
      snackbar.show(GENERAL_CONNECTION_ERROR);
    } finally {
      grecaptcha.reset(recaptchaWidgetId);
      loadingController.disableLoading();
    }
  });
}
