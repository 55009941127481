const GENERAL_CONNECTION_ERROR =
  "Wystąpił problem w czasie połączenia z serwerem";

// checks for FetchError and returns it
// otherwise rethrows
function obtainFetchError(errorObj) {
  const { name } = errorObj;
  if (name === "FetchError") {
    return errorObj;
  } else {
    // handle other errors
    throw errorObj;
  }
}

class FetchError extends Error {
  constructor(errors, status, ...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FetchError);
    }

    this.name = "FetchError";
    this.errors = errors;
    this.status = status;
    // Custom debugging information
    this.date = new Date();
  }
}

export { obtainFetchError, FetchError, GENERAL_CONNECTION_ERROR };
