import "modernizr";

import "../scss/main.scss";

import cookiesBanner from "./cookiesBanner.js";
import menu from "./menu.js";
import brandsSlider from "./brandsSlider.js";
import productsSlider from "./productsSlider.js";
import contactForm from "./contactForm.js";
import contactMap from "./contactMap.js";

document.addEventListener("DOMContentLoaded", function() {
  cookiesBanner();
  menu();
  brandsSlider();
  productsSlider();
  contactForm();
  contactMap();
});
