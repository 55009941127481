import Splide from "@splidejs/splide";

function initialize() {
  const el = document.querySelector(".js-homepage-section-products-splide");

  if (!el) {
    return;
  }

  const splideInstance = new Splide(el, {
    type: "loop",
    perPage: 3,
    perMove: 1,
    pagination: false,
    autoplay: true,
    interval: 3000,
    breakpoints: {
      "900": {
        perPage: 2,
      },
      "600": {
        perPage: 1,
      },
    },
  }).mount();

  return splideInstance;
}

export default initialize;
