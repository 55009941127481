import { FetchError, GENERAL_CONNECTION_ERROR } from "./errors";

const mainAPIPoint = "/api/v1";

export async function fetchFromApi(path, init = {}) {
  // copy to avoid side-effects on the passed object
  const options = { json: true, ...init };

  if (!options["headers"]) {
    options["headers"] = {};
  }

  // Handle the data
  if (options["data"]) {
    console.assert(!options["body"] || !options["data"]);
    options["headers"]["Content-Type"] = "application/json";
    options["body"] = JSON.stringify(options["data"]);
  }

  // Add API path prefix
  const fullPath = mainAPIPoint + path;

  const response = await fetch(fullPath, options);

  if (response.ok) {
    if (options.json) {
      const bodyText = await response.text();
      if (bodyText) {
        return JSON.parse(bodyText);
      }
      return null;
    }
    return response;
  }

  if (response.status === 400) {
    const errors = await response.json();
    const error = new FetchError(errors, response.status);
    console.error(error);
    throw error;
  } else {
    const errors = {
      non_field_errors: [GENERAL_CONNECTION_ERROR],
    };
    const error = new FetchError(errors, response.status);
    console.error(error);
    throw error;
  }
}
