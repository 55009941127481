import Splide from "@splidejs/splide";

function initialize() {
  const el = document.querySelector(".js-homepage-section-brands-splide");

  if (!el) {
    return;
  }

  const splideInstance = new Splide(el, {
    type: "loop",
    perPage: 3,
    arrows: false,
    autoplay: true,
    interval: 9000,
    breakpoints: {
      "1440": {
        perPage: 3,
        interval: 9000,
      },
      "768": {
        perPage: 1,
        interval: 3000,
      },
    },
  }).mount();

  return splideInstance;
}

export default initialize;
