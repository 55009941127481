export default function handleMenu() {
  const hamburger = document.querySelector(".top-menu .hamburger");
  const mobileMenu = document.querySelector(".top-menu .menu--mobile");
  const desktopMenu = document.querySelector(".top-menu .menu--desktop");
  const desktopSubmenusContainer = document.querySelector(
    ".top-menu .menu__submenu-items--desktop",
  );

  if (!hamburger || !mobileMenu || !desktopMenu || !desktopSubmenusContainer) {
    return;
  }

  function toggleMenu() {
    hamburger.classList.toggle("is-active");
    mobileMenu.classList.toggle("is-open");
  }

  function closeMenu() {
    hamburger.classList.remove("is-active");
    mobileMenu.classList.remove("is-open");
  }

  hamburger.addEventListener("click", toggleMenu);

  function handleMobileMenuLinks() {
    const menuLinks = mobileMenu.querySelectorAll("a");
    menuLinks.forEach(el => {
      el.addEventListener("click", closeMenu);
    });

    // handles expandable submenu items
    const menuItems = mobileMenu.querySelectorAll(".menu-item");
    menuItems.forEach(menuItem => {
      const submenu = menuItem.querySelector(".menu-item__submenu-wrap");
      if (!submenu) {
        return;
      }
      const height = menuItem.scrollHeight;
      submenu.style.overflow = "hidden";
      const menuItemLink = menuItem.querySelector(".menu-item__link");
      menuItemLink.addEventListener("click", function() {
        if (submenu.classList.contains("is-open")) {
          submenu.style.maxHeight = null;
          submenu.classList.remove("is-open");
          menuItemLink.classList.remove("has-open-submenu");
          return;
        }
        submenu.style.maxHeight = `${height}px`;
        submenu.classList.add("is-open");
        menuItemLink.classList.add("has-open-submenu");
      });
    });
  }

  function handleDesktopMenuLinks() {
    const menuItems = desktopMenu.querySelectorAll(".menu-item__link");
    const submenus = desktopSubmenusContainer.querySelectorAll(
      ".menu-item__submenu-wrap--desktop",
    );

    menuItems.forEach(menuItem => {
      const submenuIndex = menuItem.dataset.submenuIndex;
      if (!submenuIndex && submenuIndex !== 0) {
        return;
      }
      const submenu = [...submenus].find(
        submenu => submenu.dataset.itemIndex === submenuIndex,
      );
      menuItem.addEventListener("click", function() {
        // close all other open submenus
        const openSubmenus = desktopSubmenusContainer.querySelectorAll(
          ".menu-item__submenu-wrap--desktop.is-open",
        );
        openSubmenus.forEach(openSubmenu => {
          if (openSubmenu === submenu) {
            return;
          }
          const menuItem = [...menuItems][openSubmenu.dataset.itemIndex];
          menuItem.classList.remove("has-open-submenu");
          openSubmenu.classList.remove("is-open");
        });

        // handle submenu toggle
        if (submenu.classList.contains("is-open")) {
          submenu.classList.remove("is-open");
          menuItem.classList.remove("has-open-submenu");
          return;
        }
        submenu.classList.add("is-open");
        menuItem.classList.add("has-open-submenu");
      });
    });
  }

  handleMobileMenuLinks();
  handleDesktopMenuLinks();
}
